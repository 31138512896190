<template>
  <div
    class="wrapper"
    v-if="exhibition !== null && artwork !== null"
    :style="{background:`linear-gradient(90deg, hsla(0, 0%, 0%, .6), hsla(0, 0%, 0%, .8) 45%), url(${getImage(exhibition.image)})`}"
  >
    <div class="titles">
      <h1>{{ exhibition.name }}</h1>
      <h2>{{ artwork.name }}</h2>
    </div>
    <swiper
      class="cards"
      id="images"
      slides-per-view="auto"
      :centered-slides="true"
      :grab-cursor="true"
      navigation
      @slideChange="slideChange"
      @update="swiperUpdate"
    >
      <swiper-slide
        v-for="(detail, index) in details" :key="detail.id" :virtualIndex="index"
        class="cards_item card"
        :class="{ 'card--landscape' : detail.media.orientation == 'landscape' || detail.media.orientation === undefined}"
      >
        <div class="card_wrapper">
          <vue-plyr :ref="`plyr${index}`" v-if="detail.media.type == 'video'" class="player" :options="plyrOptions">
            <video
              controls
            >
              <source
                :src="getImage(detail.media)"
                type="video/mp4"
              />
            </video>
          </vue-plyr>
          <figure class="card_img" v-if="detail.media.type == 'image'">
            <img :src="getImage(detail.media)" alt="">
            <div class="card_icon" @click="zoom(index)">
              <svg class="icon icon-size-fullscreen"><use xlink:href="#icon-size-fullscreen"></use></svg>
            </div>
          </figure>
          <div class="card_text text" v-if="detail.description !== ''">
            <div class="text_wrapper">
              <h3 class="text_title" v-html="detail.name"></h3>
              <div class="text_description" v-html="detail.description"></div>
            </div>
          </div>
        </div>
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>
  import SwiperCore, { Virtual, Navigation } from 'swiper'
  import { Swiper, SwiperSlide } from 'swiper/vue'
  SwiperCore.use([Virtual, Navigation])
  import VuePlyr from 'vue-plyr'
  import 'vue-plyr/dist/vue-plyr.css'
  import { mapState, mapActions } from 'vuex'
  import 'viewerjs/dist/viewer.css'
  import Viewer from 'viewerjs'
  
  export default {
    data() {
      return {
        plyrOptions: {
          controls: ['play-large', 'play', 'progress', 'current-time', 'fullscreen'],
          resetOnEnd: true,
        },
        apiUrl: process.env.VUE_APP_API_URL,
        viewer: null,
      }
    },
    components: {
      Swiper,
      SwiperSlide,
      VuePlyr,
    },
    computed: {
      ...mapState(
        'exhibitions',
        {
          exhibition: 'item',
        }
      ),
      ...mapState(
        'artworks',
        {
          artwork: 'item',
        }
      ),
      ...mapState(
        'details',
        {
          details: 'items',
        }
      ),
    },
    mounted() {
      this.getExhibition(this.$route.params.exhibitionId)
      this.getArtwork(this.$route.params.artworkId)
      this.getDetails('artworks/' + this.$route.params.artworkId)
    },
    methods: {
      ...mapActions(
        'exhibitions',
        {
          getExhibition: 'getItem',
          resetExhibition: 'resetItem',
        }
      ),
      ...mapActions(
        'artworks',
        {
          getArtwork: 'getItem',
          resetArtwork: 'resetItem',
        }
      ),
      ...mapActions(
        'details',
        {
          getDetails: 'getItems',
          resetDetails: 'resetItems',
        }
      ),
      slideChange(swiper) {
        let plyr = this.$refs['plyr' + swiper.previousIndex]

        if (plyr !== undefined) {
          plyr.player.pause()
          plyr.player.restart()
        }
      },
      getImage(image) {
        if (image !== undefined) {
          return this.apiUrl + image.contentUrl
        }
        
        return '';
      },
      swiperUpdate() {
        if (this.viewer === null) {
          this.viewer = new Viewer(document.getElementById('images'), {
            title: false,
            navbar: false,
            toolbar: false,
            loop: false,
            rotatable: false,
            slideOnTouch: false,
            minZoomRatio: .5,
            maxZoomRatio: 1.5
          })
        }
      },
      zoom(index) {
        this.viewer.view(index)
      }
    },
  }
</script>

<style lang="scss">
.swiper {
  &-wrapper {
    align-items: center;
  }

  &-button-prev,
  &-container-rtl &-button-next {
    left: 8px;
  }

  &-button-next,
  &-container-rtl &-button-prev {
    right: 8px;
  }

  &-slide-zoomed {
    .swiper-zoom-container {
      z-index: 100;
    }
  }
}
</style>

<style lang="scss" scoped>
.titles {
  flex: 0 0 320px;
  margin-right: 32px;
}
h1 {
  font-size: 56px;
  color: #fff;
  text-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  margin-bottom: 24px;
}

h2 {
  font-weight: 400;
  font-size: 24px;
  color: #fff;
  text-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  margin-bottom: 40px;
}

h3 {
  font-size: 24px;
  font-weight: normal;
  margin-bottom: 32px;
}

.card {
  display: flex;
  justify-content: center;
  color: #fff;
  margin-right: 1px;

  &_img,
  video {
    overflow: hidden;
    display: flex;
    align-items: flex-start;
    max-width: 720px;
    max-height: calc(100vh - 200px);
    margin: 0;
    position: relative;

    & img {
      display: flex;
      max-width: 100%;
      max-height: calc(100vh - 200px);
    }

    & .card_icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateY(-50%) translateX(-50%);
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #2b2b2b;
      border-radius: 50%;
      width: 64px;
      height: 64px;
      opacity: .6;

      & .icon {
        width: 32px;
        height: 32px;
      }
    }
  }

  &_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: calc(100% - 80px);
    border-radius: 5px;
    overflow: hidden;
  }

  &--landscape {
    & .card_wrapper {
      display: block;
    }

    & .card_img,
    video {
      max-width: 100%;
    }

    & .text {
      align-items: flex-start;

      &_wrapper {
        max-height: 256px;
      }
    }
  }
}

.text {
  background-color: var(--main-color);
  padding: 32px 40px;
  flex: 0 0 400px;
  align-self: stretch;
  display: flex;
  align-items: center;

  &_wrapper {
    overflow-y: auto;
  }

  &_description {
    font-size: 18px;
  }
}
</style>