import api from './../../services/api.js'

export default {
  namespaced: true,
  state: {
    items: [],
  },
  mutations: {
    SET_ITEMS(state, data) {
      state.items = data
    },
  },
  actions: {
    // Appels API
    getItems({commit}, artwork) {
      api.details.read(artwork).then(response => {
        commit('SET_ITEMS', response.data)
      })
    },
  }
}
