<template>
  <div
    class="wrapper"
    v-if="exhibition !== null"
    :style="{background:`linear-gradient(90deg, hsla(0, 0%, 0%, .6), hsla(0, 0%, 0%, .8) 45%), url(${getImage(exhibition.image)})`}">
    <h1>{{ exhibition.name }}</h1>
    <swiper
      class="cards"
      slides-per-view="auto"
      :slides-per-column="2"
      slides-per-column-fill="row"
      :grab-cursor="true"
      navigation
    >
      <swiper-slide v-for="artwork in artworks" :key="artwork.id" :virtualIndex="artwork.id" class="cards_item card" :style="{background:`linear-gradient(180deg, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.8) 100%), url(${getImage(artwork.image)})`}">
        <router-link :to="{ name: 'details', params: { exhibitionId: exhibition.id, artworkId: artwork.id }}">
          <span class="card_link">+</span>
          <h2 class="card_title">{{ artwork.name }}</h2>
        </router-link>
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>
  import SwiperCore, { Virtual, Navigation } from 'swiper'
  import { Swiper, SwiperSlide } from 'swiper/vue'
  SwiperCore.use([Virtual, Navigation])
  import { mapState, mapActions } from 'vuex'
  
  export default {
    data() {
      return {
        backButton: true,
        apiUrl: process.env.VUE_APP_API_URL,
      }
    },
    components: {
      Swiper,
      SwiperSlide,
    },
    computed: {
      ...mapState(
        'artworks',
        {
          artworks: 'items',
        }
      ),
      ...mapState(
        'exhibitions',
        {
          exhibition: 'item',
        }
      ),
    },
    mounted() {
      this.getExhibition(this.$route.params.id)
      this.getArtworks('exhibitions/' + this.$route.params.id)
    },
    methods: {
      ...mapActions(
        'artworks',
        {
          getArtworks: 'getItems',
          resetArtworks: 'resetItems',
        }
      ),
      ...mapActions(
        'exhibitions',
        {
          getExhibition: 'getItem',
          resetExhibition: 'resetItem',
        }
      ),
      getImage(image) {
        if (image !== undefined) {
          return this.apiUrl + image.contentUrl
        }
        
        return '';
      }
    },
    beforeUnmount() {
      this.resetExhibition()
      this.resetArtworks()
    }
  }
</script>

<style lang="scss" scoped>
h1 {
  font-size: 72px;
  color: #fff;
  text-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  margin-bottom: 20px;
  flex: 0 0 400px;
  margin-right: 40px;
}

.swiper {
  &-slide {
    width: 320px;
    height: 320px;
  }

  &-button-prev,
  &-container-rtl &-button-next {
    left: 0 !important;
  }

  &-button-next,
  &-container-rtl &-button-prev {
    right: 0 !important;
  }
}

.cards {
  &_item {
    margin: 40px 40px;
  }
}

.card {
  position: relative;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  text-align: center;
  background-color: #222 !important;
  background-position: center !important;
  background-size: cover !important;

  > a {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    text-decoration: none;
  }

  &_link {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 64px;
    height: 64px;
    border-radius: 50%;
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.15);
    background-color: #AE4646;
    color: #fff;
    font-size: 30px;
    text-decoration: none;
    transition: all .3s;
  }

  &_title {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    color: #fff;
    font-weight: 400;
    font-size: 26px;
    padding: 30px;
  }

  &:hover,
  &:focus {
    .card_link {
      transform: scale(1.2);
    }
    
  }
}
</style>