<template>
  <swiper
    class="categories"
    slides-per-view="4"
    :grab-cursor="true"
    navigation
    @swiper="setControlledSwiper"
    @slideChange="slideChange"
  >
    <swiper-slide
      v-for="(exhibition, index) in exhibitions" :key="exhibition.id" :virtualIndex="index"
      class="categories_item category"
      :style="{backgroundImage:`url(${getImage(exhibition.image)})`}"
      @click="setActive"
    >
      <div class="category_container">
        <h2 class="category_title">{{ exhibition.name }}</h2>
        <div class="category_content">
          <div v-html="exhibition.description"></div>
          <router-link :to="{ name: 'artworks', params: { id: exhibition.id }}" class="category_button button button--main">
            Découvrir les documents
            <svg class="icon icon-arrow-right-circle"><use xlink:href="#icon-arrow-right-circle"></use></svg>
          </router-link>
        </div>
      </div>
    </swiper-slide>
  </swiper>
</template>

<script>
  import SwiperCore, { Virtual, Navigation } from 'swiper'
  import { Swiper, SwiperSlide } from 'swiper/vue'
  SwiperCore.use([Virtual, Navigation])
  import { mapState, mapActions } from 'vuex'
  
  export default {
    data() {
      return {
        backButton: false,
        controlledSwiper: null,
        test: false,
        slideSizeWhenOpened: 1.13,
        apiUrl: process.env.VUE_APP_API_URL,
      }
    },
    components: {
      Swiper,
      SwiperSlide,
    },
    computed: {
      ...mapState(
        'exhibitions',
        {
          exhibitions: 'items',
        }
      ),
    },
    mounted() {
      this.getExhibitions()
    },
    methods: {
      ...mapActions(
        'exhibitions',
        {
          getExhibitions: 'getItems',
        }
      ),
      setControlledSwiper(swiper) {
        this.controlledSwiper = swiper;
      },
      setActive(swiper) {
        let target = swiper.target.closest('.swiper-slide')

        if (this.controlledSwiper.slides !== undefined) {
          let index = target.dataset.swiperSlideIndex
          let params = this.controlledSwiper.params
          this.removeAllActiveDataset()

          if (params.slidesPerView === this.slideSizeWhenOpened) {
            this.removeActive()
          } else {
            this.setActiveDataset(target)
            params.slidesPerView = this.slideSizeWhenOpened

            if (index != 0 && index != (this.controlledSwiper.slides.length - 1)) {
              params.centeredSlides = true
            } else {
              params.centeredSlides = false
            }
            
            this.controlledSwiper.update()
            this.controlledSwiper.slideTo(index)
          }
        }
      },
      slideChange() {
        let index = this.controlledSwiper.activeIndex
        let params = this.controlledSwiper.params
        
        if ([0, (this.controlledSwiper.slides.length - 1)].includes(index)) {
          params.centeredSlides = false
        } else {
          if (params.slidesPerView === this.slideSizeWhenOpened) {
            params.centeredSlides = true
          }
        }
        
        this.controlledSwiper.update()

        if (params.slidesPerView === this.slideSizeWhenOpened) {
          this.removeAllActiveDataset()
          this.setActiveDataset(this.controlledSwiper.slides[index])
        }
      },
      removeActive() {
        this.removeAllActiveDataset()
        let params = this.controlledSwiper.params
        params.slidesPerView = 4
        params.centeredSlides = false
        this.controlledSwiper.update()
      },
      setActiveDataset(target) {
        target.dataset.active = 'active'
      },
      removeAllActiveDataset() {
        this.controlledSwiper.slides.forEach(function(element){
          delete element.dataset.active
        })
      },
      getImage(image) {
        if (image !== undefined) {
          return this.apiUrl + image.contentUrl
        }
        
        return '';
      }
    }
  }
</script>

<style>
.swiper-wrapper {
  transition-duration: .3s !important;
}
</style>

<style lang="scss" scoped>
.category {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  height: 100vh;
  background-position: center;
  background-size: cover;
  padding: 10vh 32px;
  transition: all .3s;

  * {
    position: relative;
    z-index: 1;
  }

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: linear-gradient(rgba(0,0,0,0), rgba(0,0,0,0.2) 50%, rgba(0,0,0,1) 100%);
  }

  &_container {
    max-width: 1120px;
    width: 100%;
  }

  &_title {
    font-size: 56px;
    color: #fff;
    text-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
  }

  &_content {
    visibility: hidden;
    max-height: .0001px;
    overflow: hidden;
    color: #fff;
    font-size: 24px;
    line-height: 1.5;
    transition: all .2s;
  }

  &_button {
    display: flex;
    align-items: center;
    margin-top: 50px;
    float: right;
    font-size: 24px;
    padding: 20px 48px;

    & .icon {
      margin-left: 16px;
      width: 28px;
      height: 28px;
    }
  }

  &[data-active="active"] {
    padding-left: 80px;
    padding-right: 80px;
    transition: all .3s;

    .category_title {
      font-size: 72px;
      margin-bottom: 0;
    }

    &:before {
      background-image: linear-gradient(rgba(0,0,0,0), rgba(0,0,0,.4) 20%, rgba(0,0,0,1) 100%);
    }

    .category_content {
      visibility: visible;
      max-height: 100vh;
      transition: all 1s .3s;
    }
  }
}

.navigation {
  &-button {
    position: fixed;
    top: calc(50vh - 30px);
    z-index: 10;

    &--start {
      left: 120px;
    }

    &--end {
      right: 20px;
    }
  }
}
</style>
