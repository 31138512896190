<template>
  <div class="home" @click="opened = true" :class="{opened: opened}">
    <img src="./assets/museum-logo.png" alt="Logo">
    <p>Touchez l’écran pour accéder aux expositions</p>
    <img src="./assets/home-gesture.svg" alt="Double click">
  </div>
  <div class="sidebar">
    <router-link to="/" class="sidebar_button button button--is-square">
      <svg class="icon icon-grid"><use xlink:href="#icon-grid"></use></svg>
    </router-link>
    <button v-show="backButton" class="sidebar_button button button--is-square" @click="$router.back()">
      <svg class="icon icon-action-undo"><use xlink:href="#icon-action-undo"></use></svg>
    </button>
  </div>
  <router-view v-slot="{ Component }">
    <transition name="scale" mode="out-in">
      <component :is="Component" />
    </transition>
  </router-view>
</template>

<script>  
  export default {
    data() {
      return {
        backButton: true,
        opened: false,
      }
    },
  }
</script>

<style lang="scss">
@import 'swiper/swiper.scss';
@import 'swiper/components/navigation/navigation.scss';

:root {
  --main-color: #ae4646;
}

html {
  box-sizing: border-box;
  scroll-behavior: smooth;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  margin: 0;
  font-family: "Lato", sans-serif;
  margin-left: 100px;
  background-color: #222;
}

.icon {
  display: inline-block;
  width: 24px;
  height: 24px;
  stroke-width: 0;
  stroke: currentColor;
  fill: currentColor;
}

button {
  cursor: pointer;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Antonio", sans-serif;
  font-weight: bold;
  margin: 0;
}

.button {
  display: inline-block;
  text-decoration: none;
  color: #c7c7c7;
  background-color: #2b2b2b;
  border-radius: 50px;
  padding: 10px 20px;
  border: none;
  transition: all .3s;

  &--is-square {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    min-width: 64px;
    min-height: 64px;
  }

  &--main {
    background-color: var(--main-color);
    color: #fff;
  }

  & .icon {
    transition: all .3s;
  }

  &:hover {
    background-color: var(--main-color);
    color: #fff;

    & .icon {
      transform: scale(1.2);
    }
  }
}

.scale {
  &-enter-active,
  &-leave-active {
    transition: all .4s ease;
  }

  &-enter-from,
  &--leave-to {
    opacity: 0;
    transform: scale(0.8);
    box-shadow: 0 0 100px rgba(0, 0, 0, 1);
  }
}

.home {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #711300;
  z-index: 999;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: all .6s;

  p {
    font-size: 24px;
    color: #fff;
    margin-top: 120px;
    margin-bottom: 56px;
  }

  img:last-child {
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    animation-name: touch;
    transform: scale(0.8);
  }

  @keyframes touch {
    0% {
        opacity: 0.2;
    }
    100% {
        opacity: 1;
        transform: scale(1);
    }
  }

  &.opened {
    top: -100%;
    bottom: 100%;
  }
}

.wrapper {
  display: flex;
  align-items: center;
  padding: 0 80px;
  height: 100vh;
  background-position: center !important;
  background-size: cover !important;
}

.swiper {
  &-wrapper {
    box-sizing: border-box !important;
  }

  &-button {
    &-prev,
    &-next {
      width: 64px !important;
      height: 64px !important;
      background-color: #fff;
      border-radius: 50%;
      box-shadow: 0 0 20px rgba(0, 0, 0, .8);
      margin-top: auto !important;
      transform: translateY(-50%);

      &:after {
        font-size: 24px !important;
        color: #222222;
      }

      & .icon {
        width: 32px;
        height: 32px;
        color: #2b2b2b;
      }
    }

    &-prev,
    .swiper-container-rtl &-next {
      left: 24px;
    }

    &-next,
    .swiper-container-rtl &-prev {
      right: 24px;
    }

    &-next.swiper-button-disabled,
    &-prev.swiper-button-disabled {
      opacity: 0;
    }
  }
}
</style>

<style scoped>
.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  height: 100vh;
  z-index: 10;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100px;
  background-color: #414141;
  padding: 18px;
}
</style>

