import { createStore } from 'vuex'
import exhibitions from './modules/exhibitions.js'
import artworks from './modules/artworks.js'
import details from './modules/details.js'

export default createStore({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    'exhibitions': exhibitions,
    'artworks': artworks,
    'details': details,
  }
})
