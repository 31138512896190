import axios from 'axios'

const apiHeader = { headers: { Accept: 'application/json' } }
const axiosInstance = axios.create()
axiosInstance.defaults.baseURL = process.env.VUE_APP_API_URL_WITH_PREFIX

export default {
  exhibitions: {
    endpoint: 'exhibitions',
    readOne(id) {
      return axiosInstance.get(
        this.endpoint + '/' + id,
        apiHeader
      )
    },
    read() {
      return axiosInstance.get(
        this.endpoint,
        apiHeader
      )
    },
  },
  artworks: {
    endpoint: 'artworks',
    readOne(id) {
      return axiosInstance.get(
        this.endpoint + '/' + id,
        apiHeader
      )
    },
    read(exhibition) {
      return axiosInstance.get(
        exhibition + '/' + this.endpoint,
        apiHeader
      )
    },
  },
  details: {
    endpoint: 'details',
    read(artwork) {
      return axiosInstance.get(
        artwork + '/' + this.endpoint,
        apiHeader
      )
    },
  },
  files: {
    endpoint: 'files',
    read() {
      return axiosInstance.get(
        this.endpoint,
        apiHeader
      )
    },
  }
}