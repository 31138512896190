import api from './../../services/api.js'

export default {
  namespaced: true,
  state: {
    item: null,
    items: [],
  },
  mutations: {
    SET_ITEM(state, data) {
      state.item = data
    },
    SET_ITEMS(state, data) {
      state.items = data
    },
  },
  actions: {
    // Appels API
    getItem({commit}, id) {
      api.exhibitions.readOne(id).then(response => {
        commit('SET_ITEM', response.data)
      })
    },
    resetItem({commit}) {
      commit('SET_ITEM', null)
    },
    getItems({commit}) {
      api.exhibitions.read().then(response => {
        commit('SET_ITEMS', response.data)
      })
    },
  }
}
  